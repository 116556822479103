<template>
    <div>
      <!-- Chat Button Container -->
      <div class="chat-button-container">
        <div class="curved-text">
          <svg viewBox="0 0 100 100" width="120" height="120">
            <defs>
              <path id="circle" d="M 50,50 m -37,0 a 37,37 0 1,1 74,0"></path>
            </defs>
            <text>
              <textPath href="#circle" startOffset="0">
                Chat with Puzzlium • Chat with Puzzlium •
              </textPath>
            </text>
          </svg>
        </div>
        <button 
          class="chat-icon"
          @click="toggleChat"
          :class="{ 'active': isChatOpen }"
        >
          <div class="bot-icon-wrapper">
            <img
              src="../assets/images/avatar.png"
              class="bot-icon"
              alt="Bot avatar"
            />
          </div>
        </button>
      </div>
  
      <!-- Chat Modal with Transition -->
      <Transition 
        enter-active-class="animate-in" 
        leave-active-class="animate-out"
      >
        <div v-if="isChatOpen" class="chat-modal">
          <!-- Background video -->
          <video
            class="background-video"
            src="../assets/video/square-1.mp4"
            loop
            muted
            playsinline
            autoplay
            disablePictureInPicture
            disableRemotePlayback
            ref="backgroundVideo"
          >
            <source src="../assets/video/square-1.mp4" type="video/mp4">
          </video>

          <Transition
            enter-active-class="fade-in"
            leave-active-class="fade-out"
          >
            <button class="close-button" @click="toggleChat">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
            </button>
          </Transition>
  
          <div class="chat-container">
            <!-- Chat Content -->
            <div class="chat-content">
              <div class="chat-messages" ref="messagesContainer">
                <div 
                  v-for="(message, index) in messages" 
                  :key="index" 
                  :class="['message-wrapper', message.role]"
                >
                  <div v-if="message.role === 'assistant'" class="bot-avatar-container">
                    <img
                      src="../assets/images/avatar.png"
                      class="bot-avatar"
                      alt="Bot avatar"
                    />
                  </div>
                  <div class="message">{{ message.content }}</div>
                </div>
                <div v-if="loading" class="message-wrapper assistant">
                  <div class="bot-avatar-container">
                    <img
                      src="../assets/images/avatar.png"
                      class="bot-avatar"
                      alt="Bot avatar"
                    />
                  </div>
                  <div class="message loading">
                    <div class="typing-indicator">
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </div>
                </div>
              </div>
  
              <div class="input-container">
                <input 
                  v-model="userInput"
                  @keyup.enter="sendMessage"
                  placeholder="Type your message..."
                  :disabled="loading"
                >
                <button 
                  @click="sendMessage" 
                  :disabled="loading || !userInput.trim()"
                >
                  <span v-if="!loading">Send</span>
                  <span v-else>...</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </Transition>
    </div>
  </template>
  
  <script setup>
  import { ref, onMounted, nextTick, defineExpose } from 'vue';
  import * as openaiService from '../services/openai';
  
  const messages = ref([]);
  const userInput = ref('');
  const loading = ref(false);
  const threadId = ref(null);
  const messagesContainer = ref(null);
  const isChatOpen = ref(false);
  const backgroundVideo = ref(null);
  
  onMounted(async () => {
  threadId.value = (await openaiService.createThread()).id;
  
  // Keep video playing in background
  document.addEventListener("visibilitychange", () => {
    if (backgroundVideo.value) {
      if (document.hidden) {
        backgroundVideo.value.play().catch(() => {});
      }
    }
  });
});
  
  const scrollToBottom = async () => {
    await nextTick();
    if (messagesContainer.value) {
      messagesContainer.value.scrollTop = messagesContainer.value.scrollHeight;
    }
  };
  
  const toggleChat = async () => {
    if (!isChatOpen.value) {
      isChatOpen.value = true;
      await nextTick();
      if (messages.value.length === 0) {
        messages.value.push({
          role: 'assistant',
          content: "Hello Human, I'm one of the Puzzlium God, What do you want from me ?"
        });
        await scrollToBottom();
      }
    } else {
      isChatOpen.value = false;
    }
  };
  
  const sendMessage = async () => {
    if (!userInput.value.trim() || loading.value) return;
  
    const userMessage = userInput.value;
    messages.value.push({
      role: 'user',
      content: userMessage
    });
    userInput.value = '';
    loading.value = true;
    await scrollToBottom();
  
    try {
      const response = await openaiService.sendMessage(threadId.value, userMessage);
      messages.value.push({
        role: 'assistant',
        content: response
      });
    } catch (error) {
      console.error('Error:', error);
      messages.value.push({
        role: 'system',
        content: 'Sorry, there was an error processing your message.'
      });
    } finally {
      loading.value = false;
      await scrollToBottom();
    }
  };
  
  // Expose methods to parent component
  defineExpose({
    toggleChat
  });
  </script>
  
<style scoped>
.background-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.bot-icon {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.bot-avatar {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

/* Animation Styles */
.animate-in {
  animation: modalIn 0.5s ease-out forwards;
}

.animate-out {
  animation: modalOut 0.5s ease-in forwards;
}

.fade-in {
  animation: fadeIn 0.3s ease-out forwards;
}

.fade-out {
  animation: fadeOut 0.3s ease-in forwards;
}

@keyframes modalIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes modalOut {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0.95);
  }
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes fadeOut {
  from { opacity: 1; }
  to { opacity: 0; }
}

/* Chat Layout Styles */
.chat-button-container {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.curved-text {
  position: absolute;
  width: 120px;
  height: 120px;
  animation: rotate 20s linear infinite;
}

.curved-text svg {
  fill: none;
}

.curved-text text {
  font-size: 11px;
  font-family: monospace;
  fill: #d4d4d4;
  text-shadow: 0 0 10px rgba(212, 212, 212, 0.3);
}

@keyframes rotate {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.chat-icon {
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  background: rgba(212, 212, 212, 0.15);
  border: 1px solid rgba(212, 212, 212, 0.4);
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  position: relative;
  z-index: 2;
  overflow: hidden;
}

.chat-icon.active {
  transform: scale(0.9);
  background: rgba(212, 212, 212, 0.25);
}

.bot-icon-wrapper {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chat-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: none;
  z-index: 1001;
  display: flex;
  justify-content: center;
  align-items: center;
  will-change: opacity;
  background: #212121 !important;
}

.chat-container {
  width: 100%;
  max-width: 800px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  padding: 0 1rem env(safe-area-inset-bottom);
  margin: 0 auto;
}

.close-button {
  position: fixed;
  top: 1rem;
  right: 1rem;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: rgba(212, 212, 212, 0.1);
  border: 1px solid rgba(212, 212, 212, 0.2);
  color: #d4d4d4;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  z-index: 1002;
  
  @media (min-width: 768px) {
    top: 2rem;
    right: 2rem;
  }
}

.close-button:hover {
  background: rgba(212, 212, 212, 0.2);
  transform: rotate(90deg);
}

.close-button svg {
  width: 20px;
  height: 20px;
}

.chat-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 0;
  padding-top: 4rem;
}

.chat-messages {
  flex: 1;
  overflow-y: auto;
  padding: 1rem 0;
  padding-bottom: calc(8rem + env(safe-area-inset-bottom));
  display: flex;
  flex-direction: column;
  gap: 1rem;
  -ms-overflow-style: none;
  scrollbar-width: none;
  margin-bottom: 1rem;
  -webkit-overflow-scrolling: touch;
}

.chat-messages::-webkit-scrollbar {
  display: none;
}

.message-wrapper {
  display: flex;
  gap: 0.5rem;
  align-items: flex-start;
}

.message-wrapper.user {
  justify-content: flex-end;
}

.bot-avatar-container {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid rgba(212, 212, 212, 0.4);
}

.message {
  max-width: 80%;
  padding: 0.8rem;
  border-radius: 8px;
  color: #d4d4d4;
  background: rgba(0, 0, 0, 0.7);
}

.message-wrapper.user .message {
  background: rgba(0, 0, 0, 0.7);
}

.input-container {
  display: flex;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  border-radius: 30px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  position: fixed;
  bottom: max(1rem, env(safe-area-inset-bottom));
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 2rem);
  max-width: 768px;
  z-index: 1010;
  box-shadow: 0 -4px 12px rgba(0, 0, 0, 0.1);
}

.input-container input {
  flex: 1;
  background: transparent;
  border: none;
  border-radius: 30px 0 0 30px;
  color: #d4d4d4;
  padding: 0.8rem 1.2rem;
  font-family: inherit;
  transition: all 0.3s ease;
}

.input-container input:focus {
  outline: none;
}

.input-container button {
  padding: 0.8rem 1.5rem;
  background: transparent;
  border: none;
  color: #d4d4d4;
  border-radius: 0 30px 30px 0;
  cursor: pointer;
  transition: all 0.3s ease;
}

.input-container button:hover:not(:disabled) {
  background: rgba(212, 212, 212, 0.1);
}

.input-container button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Typing Indicator */
.typing-indicator {
  display: flex;
  gap: 0.3rem;
}

.typing-indicator span {
  width: 8px;
  height: 8px;
  background: #d4d4d4;
  border-radius: 50%;
  animation: bounce 1s infinite;
}

.typing-indicator span:nth-child(2) {
  animation-delay: 0.2s;
}

.typing-indicator span:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes bounce {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-5px); }
}

@keyframes fadeInOut {
  0% { opacity: 0; }
  20% { opacity: 1; }
  80% { opacity: 1; }
  100% { opacity: 0; }
}

@keyframes pulse {
  0% {
    opacity: 0.4;
    transform: scale(0.95);
  }
  50% {
    opacity: 0.8;
    transform: scale(1.05);
  }
  100% {
    opacity: 0.4;
    transform: scale(0.95);
  }
}
  </style>