<template>
  <div>
    <!-- Navigation -->
    <div class="navigation" v-show="isVisible">
      <div 
        class="navigation-wrapper"
        :style="{ opacity: opacity }"
      >
        <div class="navigation-title">Puzzlium</div>
        <div class="nav-list">
          <div 
            v-for="(item, index) in navList" 
            :key="index"
            class="nav-item"
          >
            <!-- Chapter heading -->
            <div 
              class="nav-header"
              :class="{ 'active': activeIndex === index }"
              @click="toggleChapter(index)"
            >
              {{ index + 1 }}. {{ item.name }}
            </div>
            
            <!-- Description, video, and button -->
            <div 
              class="nav-content"
              :class="{ 'open': activeIndex === index }"
            >
              <p class="nav-description">{{ item.description }}</p>
              
              <!-- Video container for Puzzlium Begin -->
              <div v-if="index === 0" class="video-container" :class="{ 'fullscreen': isFullscreen }">
                <video
                  ref="trailerVideo"
                  class="trailer-video"
                  loop
                  :muted="isMuted"
                  playsinline
                  poster="@/assets/video/thumbnail.png"
                  @loadedmetadata="videoLoaded"
                >
                  <source src="@/assets/video/trailer.mp4" type="video/mp4">
                </video>
                
                <!-- Video Controls -->
                <div class="video-controls">
                  <button 
                    class="video-control-btn"
                    @click="togglePlay"
                    :title="isPlaying ? 'Pause' : 'Play'"
                  >
                    {{ isPlaying ? '⏸' : '▶' }}
                  </button>
                  
                  <button 
                    class="video-control-btn"
                    @click="toggleMute"
                    :title="isMuted ? 'Unmute' : 'Mute'"
                  >
                    {{ isMuted ? '🔇' : '🔊' }}
                  </button>
                  
                  <button 
                    class="video-control-btn"
                    @click="toggleFullscreen"
                    :title="isFullscreen ? 'Exit Fullscreen' : 'Fullscreen'"
                  >
                    {{ isFullscreen ? '⊹' : '⤢' }}
                  </button>
                </div>
              </div>

              <button 
                v-if="item.showButton"
                class="nav-button"
                @mouseenter="onHover(index)"
                @mouseleave="offHover()"
                @click.stop="handleClick(item)"
              >
                {{ item.buttonText || 'Enter ' + item.name }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- ChatWidget (outside navigation) -->
    <ChatWidget ref="chatWidget" />
  </div>
</template>

<script>
import gsap from 'gsap'
import ChatWidget from './ChatWidget.vue'

export default {
  name: 'Navigator',
  components: {
    ChatWidget
  },
  
  data() {
    return {
      videoElement: null,
      isVisible: false,
      opacity: 0,
      hoveredIndex: null,
      activeIndex: 0,
      isPlaying: false,
      isMuted: true,
      isFullscreen: false,
      navList: [
        {
          name: "Puzzlium Announcement",
          description: "Watch the puzzlium trailer.",
          buttonText: "Continue",
          showButton: false
        },
        {
          name: "Story",
          description: "Discover the epic tale of puzzlium universe through an immersive narrative experience.",
          buttonText: "Begin Journey",
          showButton: true
        },
        {
          name: "Art",
          description: "Enter the new Trophy NFT era! Complete the treasure hunt in time to earn an NFT. Score four in a row to win a full set. Let the adventure begin!",
          buttonText: "View Gallery",
          showButton: true
        },
        {
          name: "FAQ",
          description: "Find answers to questions about our project and vision.",
          buttonText: "View FAQ",
          showButton: true
        },
        {
          name: "Roadmap",
          description: "See our planned milestones and future developments.",
          buttonText: "View Timeline",
          showButton: true
        },
        {
          name: "Beta Game",
          description: "Dive into the first Puzzlium's treasure hunt on Solana! No time limit for this one —prove your skills, send a completion clip, and win $1,000. Ready to take on the challenge?",
          buttonText: "Launch Game",
          showButton: true
        },
        {
          name: "Chat Room",
          description: "Chat with puzzlium community live.",
          buttonText: "Open Chat",
          externalLink: "/chat",
          showButton: true
        },
        {
          name: "Chat with Puzzlium",
          description: "Talk to the puzzlium gods, enchant yourself with knowledge.",
          buttonText: "Open Chat",
          showButton: true
        },
        {
          name: "Whitepaper",
          description: "Read the whitepaper by puzzlium.",
          buttonText: "Read Whitepaper",
          externalLink: "/whitepaper/",
          showButton: true
        }
      ]
    }
  },

  mounted() {
    window.vueNavigator = this
    document.addEventListener('fullscreenchange', this.handleFullscreenChange)
  },

  beforeUnmount() {
    delete window.vueNavigator
    if (this.videoElement) {
      this.videoElement.pause()
    }
    document.removeEventListener('fullscreenchange', this.handleFullscreenChange)
  },

  methods: {
    videoLoaded(event) {
      this.videoElement = event.target;
    },

    togglePlay() {
      if (this.videoElement) {
        if (this.videoElement.paused) {
          this.videoElement.play()
          this.isPlaying = true
        } else {
          this.videoElement.pause()
          this.isPlaying = false
        }
      }
    },

    toggleMute() {
      this.isMuted = !this.isMuted
    },

    async toggleFullscreen() {
      const videoContainer = this.$el.querySelector('.video-container')
      
      if (!document.fullscreenElement) {
        try {
          await videoContainer.requestFullscreen()
          this.isFullscreen = true
        } catch (err) {
          console.error('Error attempting to enable fullscreen:', err)
        }
      } else {
        try {
          await document.exitFullscreen()
          this.isFullscreen = false
        } catch (err) {
          console.error('Error attempting to exit fullscreen:', err)
        }
      }
    },

    handleFullscreenChange() {
      this.isFullscreen = !!document.fullscreenElement
    },

    toggleChapter(index) {
      if (this.activeIndex === index) {
        this.activeIndex = null
      } else {
        this.activeIndex = index
      }
    },

    openNavigation() {
      this.isVisible = true
      gsap.to(this, {
        opacity: 1,
        duration: 3,
        ease: "power1.inOut"
      })
    },

    closeNavigation() {
      gsap.to(this, {
        opacity: 0,
        duration: 3,
        ease: "power1.inOut",
        onComplete: () => {
          this.isVisible = false
          this.$emit('navigation-closed')
          this.activeIndex = null
        }
      })
    },

    onHover(index) {
      this.hoveredIndex = index
      const appElement = document.getElementById('app')
      if (appElement) {
        appElement.style.cursor = 'pointer'
      }
    },

    offHover() {
      this.hoveredIndex = null
      const appElement = document.getElementById('app')
      if (appElement) {
        appElement.style.cursor = 'default'
      }
    },

    handleClick(item) {
      if (item.externalLink) {
        window.open(item.externalLink, '_blank')
        return
      }

      if (item.name === "Chat with Puzzlium") {
        this.$refs.chatWidget.toggleChat()
        return
      }

      if (item.name === "Beta Game") {
        const password = prompt("Enter Beta Game Password:")
        const normalizedPassword = password ? password.toLowerCase() : ''
        if (normalizedPassword === "pump.fun") {
          this.closeNavigation()
          this.$emit('navigation-action', 'game')
        } else if (password !== null) {
          alert("Incorrect password")
        }
      } else {
        const action = item.name.toLowerCase()
        this.$emit('navigation-action', action)
        this.closeNavigation()
      }
    }
  }
}
</script>

<style scoped>

.navigation {
    -ms-overflow-style: none;
    scrollbar-width: none;
}
.navigation::-webkit-scrollbar { 
    display: none;
}

.navigation {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  pointer-events: auto;
  z-index: 1000;
  overflow-y: auto;
  padding: 2rem 0;
}

.navigation-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 500px;
  margin: auto;
}

.navigation-title {
  font-size: 2rem;
  font-weight: bold;
  color: #d4d4d4;
  text-shadow: 0 0 10px rgba(212, 212, 212, 0.5);
  margin-bottom: 1rem;
  font-family: monospace;
  padding: 1rem;
  width: 90%;
  text-align: center;
  border-radius: 8px;
  backdrop-filter: blur(10px);
}

.nav-list {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem;
  border-radius: 8px;
  backdrop-filter: blur(15px);
  width: 90%;
  margin-top: 1rem;
}

.nav-item {
  border: 1px solid rgba(212, 212, 212, 0.3);
  border-radius: 4px;
  overflow: hidden;
}

.nav-header {
  padding: 1rem;
  color: #d4d4d4;
  font-size: 1.2rem;
  cursor: pointer;
  background: rgba(212, 212, 212, 0.1);
  transition: all 0.3s ease;
  font-family: monospace;
  text-shadow: 0 0 5px rgba(212, 212, 212, 0.5),
               0 0 10px rgba(212, 212, 212, 0.3),
               0 0 15px rgba(212, 212, 212, 0.2);
}

.nav-header:hover, .nav-header.active {
  background: rgba(212, 212, 212, 0.2);
}

.nav-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out, padding 0.3s ease;
  background: rgba(0, 0, 0, 0.5);
}

.nav-content.open {
  max-height: none;
  padding: 1rem;
}

.nav-description {
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 1rem;
  font-size: 0.9rem;
  line-height: 1.4;
}

.nav-button {
  width: 100%;
  padding: 0.8rem;
  background: rgba(212, 212, 212, 0.15);
  border: 1px solid rgba(212, 212, 212, 0.4);
  color: #d4d4d4;
  text-shadow: 0 0 5px rgba(212, 212, 212, 0.5);
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-family: monospace;
}

.nav-button:hover {
  background: rgba(212, 212, 212, 0.25);
  box-shadow: 0 0 15px rgba(212, 212, 212, 0.3);
  transform: scale(1.02);
}

.video-container {
  position: relative;
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  margin: 1rem 0;
}

.video-container.fullscreen {
  background: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.fullscreen .trailer-video {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.trailer-video {
  width: 100%;
  display: block;
  border-radius: 4px 4px 0 0;
}

.video-controls {
  width: 100%;
  padding: 0.5rem;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  border-radius: 0 0 4px 4px;
}

.video-control-btn {
  background: rgba(212, 212, 212, 0.15);
  border: 1px solid rgba(212, 212, 212, 0.4);
  color: #d4d4d4;
  text-shadow: 0 0 5px rgba(212, 212, 212, 0.3);
  padding: 0.5rem;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-family: monospace;
  min-width: 40px;
}

.video-control-btn:hover {
  background: rgba(212, 212, 212, 0.25);
  box-shadow: 0 0 15px rgba(212, 212, 212, 0.3);
  transform: scale(1.05);
}
</style>