//src/main.js

import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import App from './App.vue'
import Password from './components/Password.vue'
import Chat from './components/Chat.vue'

// Create router
const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      component: Password
    },
    {
      path: '/chat',
      component: Chat
    }
  ]
})

// Create app and use router
createApp(App)
  .use(router)
  .mount('#app')