import * as THREE from 'three'
import gsap from 'gsap'

export default class RoadMap {
  constructor(scene) {
    this.scene = scene
    this.globalGroup = new THREE.Group()
    this.road = [
      [
        "End of December 2024:",
        "Release of token + treasure hunt"
      ],
      [
        "End of January 2025:",
        "Release of complete treasure hunt  "
      ],
      [
        "End of February 2025:",
        "Release of impossible puzzle + huge marketing event on it"
      ],
      [
        "End of April 2025:",
        "New merch+Easter Event "
      ],
      [
        "End of June 2025:",
        "Animated song"
      ],
      [
        "End of december 2025:",
        "Tome 1 comic book+Christmas event "
      ],
      [
        "End of January 2026:",
        "New year event"
      ],
      [
        "End of February 2026:",
        "Tome 2 comic book"
      ],
      [
        "End of March 2026:",
        "Meet up with the community"
      ],
      [
        "End of April 2026:",
        "Easter event "
      ],
      [
        "May 2026:",
        "Anime movie Sneak peak"
      ],
      [
        "July 2026:",
        "Anime movie release (45 min story)"
      ],
      [
        "August 2026:",
        "Album of animated songs"
      ],
      [
        "September 2026-February 2027:",
        "Nft focus + Irl Event"
      ],
      [
        "2025-2027:",
        "Hyped focus event and project"
      ],
    ]
    this.offset = 1.2
    this.maxY = this.road.length * this.offset
    this.posY = 0
    this.anim = null
    this.boxMaterial = null
    this.textMaterial = null
    this.lights = []
    this.scrollSpeed = 0.01 // Adjust this value to control scroll sensitivity
  }
  
  populate(font) {
    // Box assets
    const boxGeometry = new THREE.BoxGeometry(5, 0.8, 0.2)
    this.boxMaterial = new THREE.MeshStandardMaterial({
      color: 0xffffff,
      transparent: true,
      opacity: 0
    })

    // Text assets
    this.textMaterial = new THREE.MeshBasicMaterial({
      color: 0x000000,
      transparent: true,
      opacity: 0
    })

    let i = 0
    this.meshList = []

    // Title
    const groupTop = new THREE.Group()
    const textTop = new THREE.Mesh(
      new THREE.TextGeometry("Puzzlium Roadmap", {
        font: font,
        size: 0.15,
        height: 0.03,
        curveSegments: 12,
        bevelEnabled: false,
        bevelThickness: 0.03,
        bevelSize: 0.02,
        bevelOffset: 0,
        bevelSegments: 5
      }),
      this.boxMaterial
    )
    textTop.position.x = -2
      
    groupTop.position.y = 1
    groupTop.position.z = 1
    groupTop.add(textTop)
    this.globalGroup.add(groupTop)

    for (let line of this.road) {
      // Box Group
      const group = new THREE.Group()

      // Create Box
      const box = new THREE.Mesh(
        boxGeometry,
        this.boxMaterial
      )
      group.add(box)

      // Create Date
      const date = new THREE.Mesh(
        new THREE.TextGeometry(line[0], {
          font: font,
          size: 0.08,
          height: 0.01,
          curveSegments: 12,
          bevelEnabled: false,
          bevelThickness: 0.03,
          bevelSize: 0.02,
          bevelOffset: 0,
          bevelSegments: 5
        }),
        this.textMaterial
      )
      date.position.x = -(line[0].length * 0.025)
      date.position.y = 0.1
      date.position.z = 0.27
      group.add(date)

      // Create Text
      const text = new THREE.Mesh(
        new THREE.TextGeometry(line[1], {
          font: font,
          size: 0.1,
          height: 0.015,
          curveSegments: 12,
          bevelEnabled: false,
          bevelThickness: 0.03,
          bevelSize: 0.02,
          bevelOffset: 0,
          bevelSegments: 5
        }),
        this.textMaterial
      )
      text.position.x = -(line[1].length * 0.035)
      text.position.y = -0.1
      text.position.z = 0.27
      group.add(text)

      group.position.y = -i * this.offset
      group.position.z = 1
      i += 1

      this.globalGroup.add(group)    
    }

    const group = new THREE.Group()
    const lineGeometry = new THREE.BoxGeometry(0.2, this.maxY - 0.6, 0.2)
    const line = new THREE.Mesh(
      lineGeometry,
      this.boxMaterial
    )
      
    group.position.y = -(this.maxY / 2) + 0.6
    group.position.z = 1
    group.add(line)
    this.globalGroup.add(group)

    this.scene.add(this.globalGroup)

    this.addLights()
    this.animate()
  }

  addLights() {
    const directionalLight = new THREE.DirectionalLight(0xffffff, 1.5)
    directionalLight.position.set(-1, 0.33, 0.47)

    const ambiantLight = new THREE.AmbientLight(0xffffff, 0.3)

    this.lights.push(directionalLight, ambiantLight)
    this.scene.add(directionalLight)
    this.scene.add(ambiantLight)
  }

  animate() {
    // Add wheel event listener for scroll
    document.addEventListener('wheel', (event) => {
      event.preventDefault()
      this.handleScroll(event.deltaY)
    }, { passive: false })

    // Add touch events for mobile
    let touchStartY = 0
    document.addEventListener('touchstart', (event) => {
      touchStartY = event.touches[0].clientY
    })

    document.addEventListener('touchmove', (event) => {
      const touchY = event.touches[0].clientY
      const deltaY = touchStartY - touchY
      touchStartY = touchY
      this.handleScroll(deltaY)
    })
  }

  handleScroll(deltaY) {
    const newPos = this.posY + (deltaY * this.scrollSpeed)
    
    if (newPos < this.maxY && newPos > 0) {
      this.posY = newPos

      if (this.anim) {
        this.anim.kill()
      }

      this.anim = gsap.to(this.globalGroup.position, {
        y: this.posY,
        duration: 0.5,
        ease: "power1.out",
      })
    }
  }

  display() {
    gsap.to(this.boxMaterial, {
      opacity: 1,
      duration: 2,
      ease: "power1.in",
    })
    gsap.to(this.textMaterial, {
      opacity: 1,
      duration: 2,
      ease: "power1.in",
    })
  }

  hide() {
    gsap.to(this.boxMaterial, {
      opacity: 0,
      duration: 2,
      ease: "power1.out",
    })
    gsap.to(this.textMaterial, {
      opacity: 0,
      duration: 2,
      ease: "power1.out",
    })
  }

  dispose() {
    // Remove event listeners
    document.removeEventListener('wheel', this.handleScroll)
    
    for(let group of this.globalGroup.children) {
      for (let mesh of group.children) {
        mesh.material.dispose()
        mesh.geometry.dispose()
      }
      group = undefined
    }
    this.scene.remove(this.globalGroup)

    for(let light of this.lights) {
      light.dispose()
      this.scene.remove(light)
    }
  }
}