import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js'
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass.js'
import { SMAAPass } from 'three/examples/jsm/postprocessing/SMAAPass.js'

import Cloud from './Cloud.js'
import Password from './Password.js'
import Raycaster from './Raycaster.js'
import Cube from './Cube.js'
import RoadMap from './RoadMap.js'
import BD from './Bd.js'
import Art from './Art.js'

import dat from 'dat.gui'
import gsap from 'gsap'

export default class World {
  constructor() {
    // Canvas Setup
    this.canvas = document.getElementById('canvas')
    this.scene = new THREE.Scene()
    this.camera = null
    this.renderer = null

    // State Management
    this.speed = 3
    this.cameraZ = 5
    this.renderCubeScene = false
    this.renderCloudScene = true
    this.renderCloudHole = true
    this.renderNavigation = false
    this.renderArt = false
    this.activePage = ""

    // Assets and Tools
    this.cube = null
    this.particles = null
    this.mixer = null
    this.controls = null
    this.gui = null
    this.sizes = null
    this.effectComposer = null
    this.unrealBloomPass = null

    // Bind methods that need 'this' context
    this.handleHomeClick = this.handleHomeClick.bind(this)
    this.hideNavButton = this.hideNavButton.bind(this)
    this.displayNavButton = this.displayNavButton.bind(this)
    this.openCubePage = this.openCubePage.bind(this)
    this.openRoadmap = this.openRoadmap.bind(this)
    this.openBD = this.openBD.bind(this)
    this.openArt = this.openArt.bind(this)
    this.resetControls = this.resetControls.bind(this)

    // Core Setup
    this.createCamera()
    this.createRenderer()
    this.orbitControl()
    this.postProcessing()
    this.navButtonEvent()

    // Components Initialization
    this.Cloud = new Cloud({
      canvas: this.canvas,
      scene: this.scene
    })
    this.Password = new Password(this.scene, this.camera)
    this.Raycaster = new Raycaster()
    this.Cube = new Cube(this.scene)
    this.RoadMap = new RoadMap(this.scene)
    this.Bd = new BD(this.scene, this.camera)
    this.Art = new Art(this.scene, this.camera)

    // Development Tools
    this.checkDevMode()

    // Animation Setup
    this.date = new THREE.Clock()
    this.lastTime = 0
    this.animate()

    // Window Event Handling
    this.resize()
    window.addEventListener('resize', () => this.resize())
  }

  createCamera() {
    this.camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.01, 1000)
    this.camera.position.z = this.cameraZ
  }

  createRenderer() {
    this.renderer = new THREE.WebGLRenderer({
      canvas: this.canvas,
      antialias: true
    })
    this.renderer.physicallyCorrectLight = true
  }

  orbitControl() {
    this.controls = new OrbitControls(this.camera, this.canvas)
    this.controls.zoomSpeed = 0.5
    this.controls.enableDamping = true
    this.controls.enablePan = false
    this.controls.enabled = false
  }

  animate() {
    const tick = () => {
      this.render()
      requestAnimationFrame(tick)
    }
    tick()
  }

  render() {
    const elapsed = this.date.getElapsedTime()
    const deltaTime = elapsed - this.lastTime
    this.lastTime = elapsed

    // Render Cube Scene
    if (this.renderCubeScene) {
      this.Cube.render(deltaTime, elapsed)
      this.controls.update()
    }

    // Render Cloud Scene
    if (this.renderCloudScene) {
      this.Cloud.plane.material.uniforms.uTime.value = elapsed
    }

    if (this.renderCloudHole) {
      this.Raycaster.raycast(this.sizes, this.camera, [this.Cloud.hitbox], this.Cloud)
    }

    // Render Art
    if (this.renderArt) {
      this.Raycaster.raycast(this.sizes, this.camera, this.Art.meshList, this.Art)
    }

    // Render final composition
    this.effectComposer.render(this.scene, this.camera)
  }

  resize() {
    this.sizes = {
      width: window.innerWidth,
      height: window.innerHeight
    }

    // Update camera position based on screen size
    if (this.sizes.width > this.sizes.height) {
      this.cameraZ = 5
    } else {
      this.cameraZ = 7
    }

    const updateCamera = this.renderNavigation || this.renderCloudHole || this.activePage === "roadmap"
    if (updateCamera && this.camera.position.z !== this.cameraZ) {
      this.camera.position.z = this.cameraZ
    }

    // Update camera properties
    this.camera.aspect = this.sizes.width / this.sizes.height
    this.camera.updateProjectionMatrix()

    // Update renderer
    this.renderer.setSize(this.sizes.width, this.sizes.height)
    this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

    // Update effect composer
    this.effectComposer.setSize(this.sizes.width, this.sizes.height)
    this.effectComposer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

    // Update cloud if active
    if (this.renderCloudScene) {
      this.Cloud.resize(this.sizes)
    }
  }

  postProcessing() {
    this.effectComposer = new EffectComposer(this.renderer)
    
    const renderPass = new RenderPass(this.scene, this.camera)
    this.effectComposer.addPass(renderPass)

    // Bloom effect
    this.unrealBloomPass = new UnrealBloomPass()
    this.unrealBloomPass.strength = 0.11
    this.unrealBloomPass.radius = 0.1
    this.unrealBloomPass.threshold = 0
    this.effectComposer.addPass(this.unrealBloomPass)

    // Anti-aliasing
    const smaaPass = new SMAAPass()
    this.effectComposer.addPass(smaaPass)
  }

  navButtonEvent() {
    const homeButton = document.getElementById('homeButton')
    if (homeButton) {
      // Remove any existing event listeners
      homeButton.removeEventListener('click', this.handleHomeClick)
      // Add new event listener with bound context
      homeButton.addEventListener('click', this.handleHomeClick)
    }
  }

  handleHomeClick() {
    this.hideNavButton()
    
    switch (this.activePage) {
      case "cube":
        this.Cube.close()
        this.resetControls()
        setTimeout(() => {
          this.Cloud.open()
          this.renderCloudScene = true
          this.renderCubeScene = false
          this.Cube.dispose()
        }, 3000)
        break

      case "roadmap":
        this.RoadMap.hide()
        setTimeout(() => {
          this.RoadMap.dispose()
        }, 2000)
        break

      case "bd":
        this.Bd.close()
        this.Bd.clearAudio()
        setTimeout(() => {
          this.Bd.dispose()
        }, 3000)
        break

      case "art":
        this.renderArt = false
        this.Art.close()
        break
    }

    setTimeout(() => {
      this.renderNavigation = true
      if (window.vueNavigator) {
        window.vueNavigator.openNavigation()
      }
    }, 3000)
  }

  displayNavButton() {
    const homeButton = document.getElementById('homeButton')
    if (homeButton) {
      homeButton.style.opacity = 1
      homeButton.style.pointerEvents = "all"
    }
  }

  hideNavButton() {
    const homeButton = document.getElementById('homeButton')
    if (homeButton) {
      homeButton.style.opacity = 0
      homeButton.style.pointerEvents = "none"
    }
  }

  resetControls() {
    this.controls.enabled = false
    gsap.to(this.camera.position, {
      x: 0,
      y: 0,
      z: 5,
      duration: 2,
      ease: "power1.inOut",
      onUpdate: () => {
        this.camera.lookAt(0, 0, 0)
      }
    })
  }

  openCubePage() {
    this.controls.enabled = true
    this.Cloud.close()

    setTimeout(() => {
      this.displayNavButton()
      this.activePage = "cube"
      this.Cube.iniScene()
      this.renderCloudScene = false
      this.renderCubeScene = true
    }, 3000)
  }

  openRoadmap() {
    setTimeout(() => {
      this.displayNavButton()
      this.activePage = "roadmap"
      this.RoadMap.populate(this.Password.font)
      this.RoadMap.display()
    }, 2000)
  }

  openBD() {
    setTimeout(() => {
      this.displayNavButton()
      this.activePage = "bd"
      this.Bd.open()
    }, 2000)
  }

  openArt() {
    setTimeout(() => {
      this.displayNavButton()
      this.activePage = "art"
      this.Art.open()
      this.renderArt = true
    }, 2000)
  }

  checkDevMode() {
    if (window.location.hash.match('dev')) {
      this.gui = new dat.GUI()
      this.Cloud.devPanel(this.gui)

      const postProcessingFolder = this.gui.addFolder('PostProcessing')
      postProcessingFolder.add(this.unrealBloomPass, 'strength', 0, 1, 0.001).name('Strength')
      postProcessingFolder.add(this.unrealBloomPass, 'radius', 0, 3, 0.001).name('Radius')
      postProcessingFolder.add(this.unrealBloomPass, 'threshold', 0, 2, 0.001).name('Threshold')
    }
  }
}