<!-- Password.vue -->
<template>
  <div>
    <div id="passwordHolder" style="display: none;" v-if="isLock">
      <div style="text-align: center;">
        <p style="color: #fff; font-weight: bold;">CA TOKEN:</p>
      </div>
      <div id="passwordBox">
        <p>Hidden Password</p>
        <div id="textBox">
          <input
            type="text"
            id="userPassword"
            @keyup="checkPassword"
            autocomplete="off"
          />
          <button class="how-modal-button" @click="showHowToFindPassword">
            How to find Password?
          </button>
        </div>
        <div class="modal" v-if="showModal">
          <span class="close" @click="closeModal">&times;</span>
          <video class="modal-video" autoplay muted loop>
            <source
              :src="require('../assets/video/how.mp4')"
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
          <p class="modal-text">
            Move the cursor / finger throughout the screen to find the password.
          </p>
        </div>
      </div>
    </div>
    <Navigator 
      ref="navigator"
      @navigation-action="handleNavigation"
      @navigation-closed="handleNavigationClosed"
    />
    <div id="ui">
      <p v-for="text of list" :key="text" class="textLine">{{ text }}</p>
    </div>
    <div id="homeButton">
      <p>&#60;</p>
    </div>
    <div id="playBD">
      <p>Play</p>
    </div>
    <div id="artMessage">
      <p>Click to Open/Close the art</p>
    </div>
    <canvas id="canvas"></canvas>

    <div id="countdownWrapper" class="countdown-wrapper">
      <div class="preloader"></div>
      <div id="countdownTimer" class="countdown-timer">10</div>
    </div>

    <video width="320" height="240" id="video-1" class="video">
      <source :src="require('../assets/video/1.mp4')" type="video/mp4" />
    </video>
    <video width="320" height="240" id="video-2" class="video">
      <source :src="require('../assets/video/2.mp4')" type="video/mp4" />
    </video>
    <video width="320" height="240" id="video-3" class="video">
      <source :src="require('../assets/video/3.mp4')" type="video/mp4" />
    </video>
    <video width="320" height="240" id="video-4" class="video">
      <source :src="require('../assets/video/4.mp4')" type="video/mp4" />
    </video>
    <video width="320" height="240" id="video-5" class="video">
      <source :src="require('../assets/video/5.mp4')" type="video/mp4" />
    </video>
    <video width="320" height="240" id="video-6" class="video">
      <source :src="require('../assets/video/6.mp4')" type="video/mp4" />
    </video>
    <video width="320" height="240" id="video-triangle" class="video">
      <source :src="require('../assets/video/triangle.mp4')" type="video/mp4" />
    </video>
    <video width="320" height="240" id="video-square" class="video">
      <source :src="require('../assets/video/square.mp4')" type="video/mp4" />
    </video>
    <video width="320" height="240" id="video-circle" class="video">
      <source :src="require('../assets/video/circle.mp4')" type="video/mp4" />
    </video>
    <video width="320" height="240" id="video-diamond" class="video">
      <source :src="require('../assets/video/diamond.mp4')" type="video/mp4" />
    </video>
  </div>
  <div v-if="displayGame">
    <div id="closeWraper">
      <button id="close" @click="closeGame">X</button>
    </div>
    <iframe
      id="game"
      src="https://i.simmer.io/@Critics/~81c5da01-b0bc-f77e-514d-fc35efdffda2"
    ></iframe>
  </div>
</template>

<script>
import World from '../assets/threeJsFunctions/World.js'
import { generatePassword } from '../assets/password.js'
import Navigator from './Navigator.vue'

export default {
  name: 'Password',
  components: {
    Navigator
  },
  data() {
    return {
      isLock: true,
      list: require('../assets/textList.json')['list'],
      password: "",
      World: null,
      displayGame: false,
      showModal: false
    }
  },

  methods: {
    checkPassword() {
      const value = document.getElementById('userPassword').value
      const passwordBox = document.getElementById('passwordBox')

      if (value === this.password) {
        passwordBox.style.opacity = 0

        if (this.World && this.World.Password) {
          this.World.Password.dispose()
        }
        
        if (this.World && this.World.Cloud) {
          this.World.Cloud.closeHole()
        }

        if (this.World) {
          this.World.renderCloudHole = false
        }
        
        setTimeout(() => {
          if (this.$refs.navigator) {
            this.$refs.navigator.openNavigation()
          }
          if (this.World) {
            this.World.renderNavigation = true
          }
        }, 3000)

        setTimeout(() => {
          this.isLock = false
        }, 500)
      }
    },

    handleNavigation(action) {
      if (!this.World) return

      switch(action) {
        case 'story':
          this.World.openBD()
          break
        case 'art':
          this.World.openArt()
          break
        case 'faq':
          this.World.openCubePage()
          break
        case 'roadmap':
          this.World.openRoadmap()
          break
        case 'game':
          this.displayGame = true
          break
      }
    },

    handleNavigationClosed() {
      if (this.World) {
        this.World.renderNavigation = false
      }
    },

    closeGame() {
      this.displayGame = false
      setTimeout(() => {
        if (this.$refs.navigator) {
          this.$refs.navigator.openNavigation()
        }
        if (this.World) {
          this.World.renderNavigation = true
        }
      }, 100)
    },

    showHowToFindPassword() {
      this.showModal = !this.showModal
    },

    closeModal() {
      this.showModal = false
    }
  },

  mounted() {
    this.password = generatePassword()
    this.World = new World()
  },

  beforeUnmount() {
    // Cleanup
    if (this.World) {
      // Add any necessary cleanup for Three.js scenes
      if (this.World.renderer) {
        this.World.renderer.dispose()
      }
      if (this.World.scene) {
        while(this.World.scene.children.length > 0){ 
          this.World.scene.remove(this.World.scene.children[0])
        }
      }
    }
  }
}
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap");

.video {
  display: none;
}

/* Game iframe styles */
#game {
  width: 100%;
  height: 100vh;
  border: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}

#closeWraper {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1001;
}

#close {
  padding: 10px 20px;
  background: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1.2rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
}

#close:hover {
  background: #f0f0f0;
  transform: scale(1.05);
}

/* Modal styles */
.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.9);
  padding: 20px;
  border-radius: 8px;
  z-index: 1000;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  color: white;
  cursor: pointer;
  font-size: 24px;
}

.modal-video {
  max-width: 100%;
  margin-bottom: 10px;
}

.modal-text {
  color: white;
  text-align: center;
}

#ui {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;

  height: 100vh;
  width: 100vw;

  color: white;

  display: flex;
  align-items: center;
  justify-content: center;

  pointer-events: none;
  font-family: "Open Sans", sans-serif;

  transition-duration: 2000ms;
  opacity: 0;
}
.textLine {
  margin-bottom: 40vh;

  position: absolute;
  text-align: center;
  font-size: 2.5em;
  padding: 0 10vw;

  transition-duration: 500ms;
  transform: translateY(30vh);
  transition-timing-function: ease-in-out;
  opacity: 0;
}
/* Password */
#passwordHolder {
  position: absolute;
  top: 0;

  height: 100vh;
  width: 100vw;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
#passwordBox {
  padding: 2vh;

  color: white;
  border: 1px solid white;

  transition-duration: 500ms;
}
#passwordBox p {
  font-size: 2em;
  text-align: center;
  margin: 0;
}
#userPassword {
  font-size: 1.5em;
  margin: 0.5em;
  padding: 0.5em;

  background-color: transparent;
  border: 2px solid white;
  color: #ffffff;

  outline: none;

  transition-duration: 500ms;
}
/* HomeButton */
#homeButton {
  position: absolute;
  z-index: 10;
  top: 0;
  color: white;
  margin: 1vh;
  padding: 1vh;

  border: 1px solid white;
  cursor: pointer;

  transition-duration: 1000ms;
  opacity: 0;
  pointer-events: none;
}
#homeButton p {
  margin: 0;
}
#playBD {
  position: absolute;
  top: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 100vh;
  width: 100vw;

  transition-duration: 500ms;
  opacity: 0;
  pointer-events: none;
}
#playBD p {
  font-size: 2em;
  width: min-content;

  color: white;
  border: 1px solid white;
  padding: 1em;

  cursor: pointer;
}
#artMessage {
  position: absolute;
  top: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 100vh;
  width: 100vw;

  transition-duration: 3000ms;
  opacity: 0;
  pointer-events: none;
}
#artMessage p {
  font-size: 2em;
  width: min-content;

  color: white;
  white-space: nowrap;
}
#game {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 5;
}
#closeWraper {
  width: 100vw;

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  right: 0;

  z-index: 10;
}
#close {
  margin: 5vh;
  background-color: transparent;
  border: 0;
  font-size: 2em;
  color: white;
  cursor: pointer;
}
.video {
  display: none;
}
@media screen and (orientation: portrait) {
  #artMessage p,
  #passwordBox p {
    font-size: 6vw;
  }
  #userPassword {
    font-size: 5vw;
  }
  .textLine {
    font-size: 3vw;
  }
}

.modal {
  position: fixed;
  z-index: 9999;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.6);
}

.modal-video {
  display: block;
  margin: 0 auto;
  max-width: 80%;
  max-height: 50vh;
  margin-top: 150px;
}

.modal-text {
  text-align: center;
  color: white;
  font-size: 17px !important;
  margin-top: 20px !important;
  padding:30px;
}

.close {
  position: absolute;
  top: 10px;
  right: 20px;
  color: white;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover,
.close:focus {
  color: #ccc;
  text-decoration: none;
  cursor: pointer;
}

.how-modal-button {
	background: #0000;
	color: #fff;
	cursor: pointer;
}

#textBox {
	display: grid;
}

.countdown-wrapper {
  position: absolute;
  bottom: 20px;
  right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: 100;
  pointer-events: none;
}

.preloader {
  width: 40px;
  height: 40px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s linear infinite;
  margin-right: 10px;
}

.countdown-timer {
  color: white;
  font-family: "Open Sans", sans-serif;
  font-size: 24px;
  font-weight: bold;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

</style>
