import * as THREE from 'three'
import gsap from 'gsap'

export default class Art {
  constructor(scene, camera) {
    this.scene = scene
    this.camera = camera
    this.lookAt = { x: 0, y: 0, z: 0 }
    this.zoom = 1
    this.isOpen = false
    this.play = null
    this.currentVideo = null
    this.currentSequence = 'intro'
    
    // Duration for each intro video in seconds
    this.introDuration = 5
    
    this.introVideos = [
      { element: document.getElementById('video-1'), position: { x: 0, y: 0, z: 2 } },
      { element: document.getElementById('video-2'), position: { x: 0, y: 0, z: 2 } },
      { element: document.getElementById('video-3'), position: { x: 0, y: 0, z: 2 } },
      { element: document.getElementById('video-4'), position: { x: 0, y: 0, z: 2 } },
      { element: document.getElementById('video-5'), position: { x: 0, y: 0, z: 2 } },
      { element: document.getElementById('video-6'), position: { x: 0, y: 0, z: 2 } }
    ]
    
    this.geometricVideos = [
      { element: document.getElementById('video-triangle'), x: -1, y: 1 },
      { element: document.getElementById('video-square'), x: 1, y: 1 },
      { element: document.getElementById('video-circle'), x: -1, y: -1 },
      { element: document.getElementById('video-diamond'), x: 1, y: -1 }
    ]

    this.currentIntroIndex = 0
    this.meshList = []
    this.introMesh = null
    
    // Initialize countdown elements
    this.countdownWrapper = document.getElementById('countdownWrapper')
    this.countdownTimer = document.getElementById('countdownTimer')
    this.countdown = null

    this.populate()
    this.addEvent()
  }

  startCountdown() {
    if (this.countdown) {
      clearInterval(this.countdown)
    }

    let timeLeft = this.introDuration
    this.countdownWrapper.style.opacity = 1
    
    const updateTimer = () => {
      this.countdownTimer.textContent = timeLeft.toString()
    }
    
    updateTimer()
    
    this.countdown = setInterval(() => {
      timeLeft -= 1
      if (timeLeft >= 0) {
        updateTimer()
      }
    }, 1000)
  }

  stopCountdown() {
    if (this.countdown) {
      clearInterval(this.countdown)
      this.countdown = null
    }
    this.countdownWrapper.style.opacity = 0
  }

  populate() {
    const introGeometry = new THREE.PlaneGeometry(2.5, 2.5)
    const geometricGeometry = new THREE.PlaneGeometry(1.5, 1.5)

    // Setup intro videos
    for (let video of this.introVideos) {
      const texture = new THREE.VideoTexture(video.element)
      const mesh = new THREE.Mesh(
        introGeometry,
        new THREE.MeshBasicMaterial({
          map: texture,
          transparent: true,
          opacity: 0
        })
      )
      mesh.position.set(video.position.x, video.position.y, video.position.z)
      mesh.lookAt(0, 0, 4)
      video.mesh = mesh

      // Loop handling for intro videos
      video.element.addEventListener('ended', () => {
        if (this.currentSequence === 'intro') {
          this.playNextIntroVideo()
        }
      })
    }

    // Setup geometric videos
    let id = 0
    for (let video of this.geometricVideos) {
      const texture = new THREE.VideoTexture(video.element)
      const mesh = new THREE.Mesh(
        geometricGeometry,
        new THREE.MeshBasicMaterial({
          map: texture,
          transparent: true,
          opacity: 0
        })
      )
      
      mesh.position.set(video.x, video.y, 2)
      mesh.lookAt(0, 0, 4)
      video.mesh = mesh
      mesh.name = id++
      this.meshList.push(mesh)

      // Pause at first frame for geometric videos
      video.element.addEventListener('loadeddata', () => {
        video.element.currentTime = 0
        video.element.pause()
      })
    }
  }

  playNextIntroVideo() {
    const currentVideo = this.introVideos[this.currentIntroIndex]
    const nextIndex = (this.currentIntroIndex + 1) % this.introVideos.length

    this.stopCountdown()
    
    gsap.to(currentVideo.mesh.material, {
      opacity: 0,
      duration: 0.5,
      ease: "power1.inOut",
      onComplete: () => {
        this.scene.remove(currentVideo.mesh)
        
        if (nextIndex === 0) {
          this.switchToGeometricSequence()
        } else {
          this.currentIntroIndex = nextIndex
          const nextVideo = this.introVideos[nextIndex]
          this.scene.add(nextVideo.mesh)
          nextVideo.element.play()
          this.startCountdown()
          gsap.to(nextVideo.mesh.material, {
            opacity: 1,
            duration: 0.5,
            ease: "power1.inOut"
          })
        }
      }
    })
  }

  switchToGeometricSequence() {
    this.currentSequence = 'geometric'
    this.stopCountdown()
    
    // Update art message text
    const artMessage = document.getElementById('artMessage')
    if (artMessage) {
      const messageText = artMessage.querySelector('p')
      if (messageText) {
        messageText.textContent = 'Click Art to Open / Close'
      }
      artMessage.style.opacity = 1
    }
    
    for (let video of this.geometricVideos) {
      this.scene.add(video.mesh)
      video.element.currentTime = 0
      video.element.pause()
      gsap.to(video.mesh.material, {
        opacity: 1,
        duration: 1,
        ease: "power1.in"
      })
    }
  }

  open() {
    this.isOpen = true
    this.currentSequence = 'intro'
    
    const firstVideo = this.introVideos[0]
    this.scene.add(firstVideo.mesh)
    firstVideo.element.play()
    this.startCountdown()
    gsap.to(firstVideo.mesh.material, {
      opacity: 1,
      duration: 1,
      ease: "power1.in"
    })
  }

  close() {
    this.stopCountdown()
    document.getElementById('artMessage').style.opacity = 0
    this.isOpen = false
    document.getElementById('app').style.cursor = "default"
    this.animCamera(0, 0, 0)
    this.zoomCamera(1)

    // Clear all videos
    if (this.currentSequence === 'intro') {
      const currentVideo = this.introVideos[this.currentIntroIndex]
      currentVideo.element.pause()
      this.scene.remove(currentVideo.mesh)
    } else {
      for (let video of this.geometricVideos) {
        video.element.pause()
        video.element.currentTime = 0
        this.scene.remove(video.mesh)
      }
    }

    this.currentSequence = 'intro'
    this.currentIntroIndex = 0
  }

  addEvent() {
    document.addEventListener('click', () => {
      if (!this.isOpen) return

      if (this.currentSequence === 'geometric') {
        document.getElementById('artMessage').style.opacity = 0
        
        if (this.play) {
          this.currentVideo.element.pause()
          this.currentVideo.element.currentTime = 0
          this.currentVideo = null
          this.play = null
          this.animCamera(0, 0, 0)
          this.zoomCamera(1)
        } else if (this.currentVideo) {
          this.play = this.currentVideo
          this.play.element.currentTime = 0
          this.play.element.play()
          this.animCamera(this.currentVideo.x, this.currentVideo.y, 2)
          this.zoomCamera(3)
        }
      }
    })
  }

  raycastCallBack(object) {
    if (this.currentSequence !== 'geometric') return
    
    if (object) {
      document.getElementById('app').style.cursor = "pointer"
      this.currentVideo = this.geometricVideos[Number(object.object.name)]
    } else {
      document.getElementById('app').style.cursor = "default"
      this.currentVideo = null
    }
  }

  animCamera(x, y, z) {
    gsap.to(this.lookAt, {
      x: x,
      y: y,
      z: z,
      duration: 1,
      ease: "power1.inOut",
      onUpdate: () => {
        this.camera.lookAt(this.lookAt.x, this.lookAt.y, this.lookAt.z)
      }
    })
  }
  
  zoomCamera(zoom) {
    gsap.to(this, {
      zoom: zoom,
      duration: 1,
      ease: "power1.out",
      onUpdate: () => {
        this.camera.zoom = this.zoom
        this.camera.updateProjectionMatrix()
      }
    })
  }
}