# ChatPage.vue
<template>
  <div>
    <AdminPanel v-if="showAdmin" @close="showAdmin = false" />
    <div class="chat-page">
      <div class="chat-container">
        <div class="terminal-header">
          <span class="terminal-title">Puzzlium Chat Room</span>
          <div class="header-buttons">
            <button class="home-button" @click="navigateToHome">
              Home
            </button>
            <button class="home-button" @click="showAdmin = true">
              Admin Login
            </button>
          </div>
        </div>
        <div class="messages-container" ref="messagesContainer">
          <div 
            v-for="message in messages" 
            :key="message.id" 
            class="message"
          >
            <span class="message-user">Puzzlium:\{{ message.username }} ></span>
            <span class="message-content">{{ filterNSFW(filterLinks(message.content)) }}</span>
            <span class="message-time">{{ formatTime(message.created_at) }}</span>
          </div>
        </div>
        <div class="message-form">
          <span class="prompt">Puzzlium:\</span>
          <input 
            v-model="newMessage" 
            type="text" 
            placeholder="Type your message..."
            maxlength="200"
            @keyup.enter="sendMessage"
            required
          />
          <button type="submit" @click.prevent="sendMessage" style="display: none;">Send</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createClient } from '@supabase/supabase-js'
import AdminPanel from './AdminPanel.vue'

export default {
  name: 'ChatPage',
  components: {
    AdminPanel
  },
  data() {
    return {
      supabase: null,
      messages: [],
      newMessage: '',
      username: '',
      subscription: null,
      autoRefreshInterval: null,
      nsfwWords: ['fuck', 'shit', 'damn', 'bitch', 'porn', 'sex', 'nigga', 'nigger'],
      showAdmin: false
    }
  },
  created() {
    // Initialize Supabase
    this.supabase = createClient(
      process.env.VUE_APP_SUPABASE_URL, 
      process.env.VUE_APP_SUPABASE_ANON_KEY
    )

    // Generate username if not exists
    this.initializeUser()

    // Fetch initial messages
    this.fetchMessages()

    // Set up real-time subscription
    this.setupRealTimeSubscription()

    // Set up auto-refresh
    this.startAutoRefresh()
  },
  methods: {
    initializeUser() {
      let username = localStorage.getItem('chat_username')
      if (!username) {
        username = `anon_${Math.floor(Math.random() * 1000)}`
        localStorage.setItem('chat_username', username)
      }
      this.username = username
    },

    async fetchMessages() {
      try {
        const { data, error } = await this.supabase
          .from('chat_messages')
          .select('*')
          .order('created_at', { ascending: false })
          .limit(100)

        if (error) throw error

        this.messages = data.reverse()
        
        this.$nextTick(() => {
          this.scrollToBottom()
        })
      } catch (error) {
        console.error('Error fetching messages:', error)
        alert('Failed to load messages. Please try again.')
      }
    },

    startAutoRefresh() {
      if (this.autoRefreshInterval) {
        clearInterval(this.autoRefreshInterval)
      }

      this.autoRefreshInterval = setInterval(() => {
        this.fetchMessages()
      }, 5000)
    },

    async sendMessage() {
      if (!this.newMessage.trim()) return

      try {
        const { error } = await this.supabase
          .from('chat_messages')
          .insert({
            content: this.filterNSFW(this.filterLinks(this.newMessage)),
            username: this.username
          })

        if (error) throw error

        this.newMessage = ''
      } catch (error) {
        console.error('Error sending message:', error)
        alert('Failed to send message. Please try again.')
      }
    },

    filterNSFW(message) {
      return this.nsfwWords.reduce((filteredMessage, word) => {
        return filteredMessage.replace(new RegExp(word, 'gi'), '***')
      }, message)
    },

    filterLinks(message) {
      return message.replace(/(https?:\/\/\S+)/g, '')
    },

    setupRealTimeSubscription() {
      if (this.subscription) {
        this.supabase.removeChannel(this.subscription)
      }

      this.subscription = this.supabase
        .channel('chat_room')
        .on(
          'postgres_changes', 
          { 
            event: 'INSERT', 
            schema: 'public', 
            table: 'chat_messages' 
          },
          (payload) => {
            console.log('Received real-time update:', payload)
            
            this.messages.push(payload.new)
            
            if (this.messages.length > 100) {
              this.messages.shift()
            }

            this.$nextTick(() => {
              this.scrollToBottom()
            })
          }
        )
        .subscribe(async (status) => {
          if (status === 'SUBSCRIBED') {
            console.log('Real-time subscription active')
          }
        })
    },

    scrollToBottom() {
      const container = this.$refs.messagesContainer
      if (container) {
        container.scrollTop = container.scrollHeight
      }
    },

    formatTime(timestamp) {
      const date = new Date(timestamp)
      return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
    },

    navigateToHome() {
      window.location.href = '/'
    }
  },
  beforeUnmount() {
    if (this.supabase && this.subscription) {
      this.supabase.removeChannel(this.subscription)
    }

    if (this.autoRefreshInterval) {
      clearInterval(this.autoRefreshInterval)
    }
  }
}
</script>

<style scoped>
.chat-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #000;
  color: #fff;
  font-family: 'Courier New', monospace;
  position: relative;
}

.chat-page::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(ellipse at center, rgba(0,0,0,0.5) 0%,rgba(0,0,0,1) 100%);
  z-index: -1;
}

.chat-container {
  width: 100%;
  max-width: 800px;
  min-height: 80vh;
  background-color: #000;
  border: 1px solid #fff;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
}

.terminal-header {
  background-color: #fff;
  color: #000;
  padding: 0.5rem 1rem;
  font-weight: bold;
  border-radius: 10px 10px 0px 0px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-buttons {
  display: flex;
  gap: 0.5rem;
}

.home-button {
  background-color: #000;
  color: #fff;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  font-family: 'Courier New', monospace;
}

.terminal-title {
  font-size: 1.2rem;
}

.messages-container {
  flex-grow: 1;
  overflow-y: auto;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.messages-container::-webkit-scrollbar {
  display: none;
}

.message {
  margin-bottom: 0.25rem;
  padding: 0.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.message-user {
  font-weight: bold;
  margin-right: 1rem;
}

.message-content {
  flex-grow: 1;
}

.message-time {
  color: #fff;
  font-size: 0.8rem;
  margin-left: 1rem;
}

.message-form {
  display: flex;
  align-items: center;
  padding: 1rem;
  position: sticky;
  bottom: 0;
  z-index: 10;
  background-color: #000;
  border-radius: 0 0 10px 10px;
  border-top: 1px solid #333;
}

.prompt {
  color: #fff;
  margin-right: 0.5rem;
}

.message-form input {
  flex-grow: 1;
  background-color: #000;
  border: none;
  color: #fff;
  padding: 0.5rem;
  margin-right: 0.5rem;
  font-family: 'Courier New', monospace;
}

.message-form button {
  display: none;
}

  @media screen and (max-width: 768px) {
  .chat-page {
    min-height: 100vh;
    padding: 0;
  }

  .chat-container {
    width: 100%;
    min-height: 100vh;
    max-width: none;
    border: none;
    border-radius: 0;
  }

  .terminal-header {
    border-radius: 0;
  }

  .messages-container {
    padding-bottom: 60px;
  }

  .message-form {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1rem;
    background-color: #000;
    border-radius: 0;
  }
}

@supports (-webkit-touch-callout: none) {
  .chat-page {
    min-height: -webkit-fill-available;
  }
  
  .chat-container {
    min-height: -webkit-fill-available;
  }
}
</style>