<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style>
body, html {
  margin: 0;
  padding: 0;
  background-color: black;
}
</style>