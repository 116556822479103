// AdminPanel.vue
<template>
  <div class="admin-panel" v-if="showAdminPanel">
    <div class="admin-container">
      <div class="terminal-header">
        <span class="terminal-title">Admin Panel</span>
        <div class="header-buttons">
          <button class="admin-button" @click="logout">
            Logout
          </button>
        </div>
      </div>
      
      <div class="messages-container">
        <div 
          v-for="message in messages" 
          :key="message.id" 
          class="admin-message"
        >
          <div class="message-info">
            <span class="message-user">{{ message.username }}</span>
            <span class="message-content">{{ message.content }}</span>
            <span class="message-time">{{ formatTime(message.created_at) }}</span>
          </div>
          <button class="delete-button" @click="deleteMessage(message.id)">
            Delete
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Login Form -->
  <div class="login-container" v-else>
    <div class="login-form">
      <h2>Login</h2>
      <input 
        type="text" 
        v-model="loginForm.email" 
        placeholder="Email"
        required
      />
      <input 
        type="password" 
        v-model="loginForm.password" 
        placeholder="Password"
        required
      />
      <button @click="login">Login</button>
      <p v-if="loginError" class="error-message">{{ loginError }}</p>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import { createClient } from '@supabase/supabase-js'

export default {
  name: 'AdminPanel',
  setup() {
    const supabase = createClient(
      process.env.VUE_APP_SUPABASE_URL,
      process.env.VUE_APP_SUPABASE_ANON_KEY
    )

    const showAdminPanel = ref(false)
    const messages = ref([])
    const loginForm = ref({
      email: '',
      password: ''
    })
    const loginError = ref('')

    const fetchMessages = async () => {
      try {
        const { data, error } = await supabase
          .from('chat_messages')
          .select('*')
          .order('created_at', { ascending: false })

        if (error) throw error
        messages.value = data
      } catch (error) {
        console.error('Error fetching messages:', error)
      }
    }

    const login = async () => {
      try {
        const { data: { user }, error } = await supabase.auth.signInWithPassword({
          email: loginForm.value.email,
          password: loginForm.value.password,
        })

        if (error) throw error

        // Verify if user has admin role
        const { data: adminData, error: adminError } = await supabase
          .from('admin_users')
          .select('*')
          .eq('user_id', user.id)
          .single()

        if (adminError || !adminData) {
          throw new Error('Unauthorized access')
        }

        showAdminPanel.value = true
        loginError.value = ''
        await fetchMessages()
      } catch (error) {
        loginError.value = error.message
        console.error('Login error:', error)
      }
    }

    const logout = async () => {
      try {
        const { error } = await supabase.auth.signOut()
        if (error) throw error
        
        showAdminPanel.value = false
        loginForm.value = { email: '', password: '' }
      } catch (error) {
        console.error('Logout error:', error)
      }
    }

    const deleteMessage = async (messageId) => {
      try {
        const { error } = await supabase
          .from('chat_messages')
          .delete()
          .eq('id', messageId)

        if (error) throw error
        
        // Refresh messages after deletion
        await fetchMessages()
      } catch (error) {
        console.error('Error deleting message:', error)
      }
    }

    const formatTime = (timestamp) => {
      const date = new Date(timestamp)
      return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
    }

    // Check for existing session on mount
    onMounted(async () => {
      const { data: { session } } = await supabase.auth.getSession()
      if (session) {
        const { data: adminData } = await supabase
          .from('admin_users')
          .select('*')
          .eq('user_id', session.user.id)
          .single()

        if (adminData) {
          showAdminPanel.value = true
          await fetchMessages()
        }
      }
    })

    return {
      showAdminPanel,
      messages,
      loginForm,
      loginError,
      login,
      logout,
      deleteMessage,
      formatTime
    }
  }
}
</script>

<style scoped>
.admin-panel {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Courier New', monospace;
}

.admin-container {
  width: 90%;
  max-width: 800px;
  height: 90vh;
  background-color: #000;
  border: 1px solid #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}

.terminal-header {
  background-color: #fff;
  color: #000;
  padding: 0.5rem 1rem;
  font-weight: bold;
  border-radius: 10px 10px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.messages-container {
  flex-grow: 1;
  overflow-y: auto;
  padding: 1rem;
  color: #fff;
}

.admin-message {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  border-bottom: 1px solid #333;
  margin-bottom: 0.5rem;
}

.message-info {
  flex-grow: 1;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.message-user {
  font-weight: bold;
  min-width: 120px;
  color: #fff;
}

.message-content {
  flex-grow: 1;
  color: #fff;
}

.message-time {
  color: #666;
  font-size: 0.8rem;
  margin-left: auto;
  margin-right: 1rem;
}

.delete-button {
  background-color: #ff4444;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  font-family: 'Courier New', monospace;
  transition: background-color 0.2s;
}

.delete-button:hover {
  background-color: #ff6666;
}

.login-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-form {
  background-color: #111;
  padding: 2rem;
  border-radius: 8px;
  border: 1px solid #fff;
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.login-form h2 {
  color: #fff;
  text-align: center;
  margin-bottom: 1rem;
  font-family: 'Courier New', monospace;
}

.login-form input {
  padding: 0.75rem;
  background-color: #000;
  border: 1px solid #333;
  color: #fff;
  border-radius: 4px;
  font-family: 'Courier New', monospace;
}

.login-form input:focus {
  border-color: #fff;
  outline: none;
}

.login-form button {
  background-color: #fff;
  color: #000;
  padding: 0.75rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-family: 'Courier New', monospace;
  font-weight: bold;
  transition: background-color 0.2s;
}

.login-form button:hover {
  background-color: #eee;
}

.error-message {
  color: #ff4444;
  font-size: 0.9rem;
  text-align: center;
}

.admin-button {
  background-color: #000;
  color: #fff;
  border: 1px solid #fff;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  font-family: 'Courier New', monospace;
  transition: all 0.2s;
}

.admin-button:hover {
  background-color: #fff;
  color: #000;
}

@media screen and (max-width: 768px) {
  .admin-container {
    width: 100%;
    height: 100vh;
    border: none;
    border-radius: 0;
  }

  .terminal-header {
    border-radius: 0;
  }

  .login-form {
    margin: 1rem;
    max-width: none;
  }

  .message-info {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
  }

  .message-time {
    margin-left: 0;
  }
}
</style>