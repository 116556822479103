// src/services/openai.js

const API_BASE_URL = process.env.NODE_ENV === 'development' 
  ? 'http://localhost:9999/.netlify/functions'  // Match netlify.toml port
  : '/.netlify/functions';

export const createThread = async () => {
  try {
    const response = await fetch(`${API_BASE_URL}/openai`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify({
        action: 'createThread'
      })
    });
    
    if (!response.ok) {
      console.error('Response status:', response.status);
      const text = await response.text();
      console.error('Response text:', text);
      throw new Error('Network response was not ok');
    }
    
    return await response.json();
  } catch (error) {
    console.error('Error creating thread:', error);
    throw error;
  }
};

export const sendMessage = async (threadId, content) => {
  try {
    const response = await fetch(`${API_BASE_URL}/openai`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify({
        action: 'sendMessage',
        threadId,
        content
      })
    });
    
    if (!response.ok) {
      console.error('Response status:', response.status);
      const text = await response.text();
      console.error('Response text:', text);
      throw new Error('Network response was not ok');
    }
    
    const data = await response.json();
    return data.response;
  } catch (error) {
    console.error('Error in sendMessage:', error);
    throw error;
  }
};